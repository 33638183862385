import TrackedItem from '@/domain/TrackedItem';

export default class TransactionLineDetail {
    public id!: number;

    public trackedItem!: TrackedItem;

    public trackedItemId!: number;

    constructor(trackedItem: TrackedItem) {
        this.trackedItem = trackedItem;
        this.trackedItemId = this.trackedItem.id;
    }
}
