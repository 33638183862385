import { API_SERVICE_URL } from '@/config/env';
import { AuthStore } from '@/store/AuthStore';

export type UploadImageEvent = {
    base64String: string;
    type: string;
};

export default class ImageUpload {
    public base64String?: string;
    public type?: string;
    public full = '';
    public thumb = '';

    private constructor() {
    }

    public static CreateBase64Image(value: UploadImageEvent): ImageUpload {
        const image = new ImageUpload();
        image.base64String = value.base64String;
        image.type = value.type;
        return image;
    }

    public static CreateImageUrl(filename: string): ImageUpload {
        const image = new ImageUpload();
        image.full = `${API_SERVICE_URL}/storage/images/${AuthStore.getInstance().clientId}/${filename}`;
        image.thumb = `${API_SERVICE_URL}/storage/thumbnails/${AuthStore.getInstance().clientId}/${filename}`;
        return image;
    }
}
