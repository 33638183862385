import TransactionLineDetail from '@/domain/TransactionLineDetail';
import TransactionLine from '@/domain/TransactionLine';
import { DTOBuilder } from '@/modules/floortrak/domain/jigsaw/dto/DTOBuilder';

export default class TransactionLineDTO extends DTOBuilder {
    public id!: number;

    public itemId!: number;

    public requestedQuantity!: number;

    public plannedQuantity!: number;

    public actualQuantity!: number;

    public transactionLineDetails: Array<TransactionLineDetail> = [];

    public unitLoadParentId?: number;

    constructor(transactionLine: TransactionLine) {
        super();
        this.map<TransactionLineDTO, TransactionLine>(this, transactionLine);

        if (transactionLine.item) this.itemId = transactionLine.item.id;

        this.actualQuantity = transactionLine.actualQuantity;
        this.requestedQuantity = transactionLine.requestedQuantity;
        this.plannedQuantity = transactionLine.plannedQuantity;
        this.transactionLineDetails = transactionLine.transactionLineDetails;
        this.unitLoadParentId = transactionLine.unitLoadParentId;
    }
}
