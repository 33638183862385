import { DTOBuilder } from '@/modules/floortrak/domain/jigsaw/dto/DTOBuilder';
import Transaction from '@/domain/Transaction';

export default class DisputeTransactionDTO extends DTOBuilder {
    public id!: number;

    public transactionLines: Array<{ itemId: number, receivedQty: number }> = [];

    public disputeNotes!: string;

    public disputeImageBase64Strings: Array<string> = [];

    constructor(transaction: Transaction) {
        super();
        this.id = transaction.id;
        this.disputeNotes = transaction.disputeNotes;
        if (transaction.transactionLines) {
            this.transactionLines = transaction.transactionLines.map((line) => ({
                itemId: line.item.id,
                receivedQty: line.receivedQuantity,
            }));
        }
        if (transaction.disputeImages) {
            this.disputeImageBase64Strings = transaction.disputeImages
                .filter((img) => img.base64String)
                .map((img) => img.base64String) as Array<string>;
        }
    }
}
